import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://75a6fc666cab0d3757ab01bb033460b0@sentry.vgnett.no/130",
  debug: false,
  environment: "production",
  release: "e24-innhold-0151ef6",
  tracesSampleRate: 0,
  sampleRate: 0.5,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});